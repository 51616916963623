import { Header } from './Header';
import { CustomerCard } from './CustomerCard/CustomerCard';
import { CustomerCardHeader } from './CustomerCard/CustomerCardHeader';
import { CustomerCardBody } from './CustomerCard/CustomerCardBody';
import { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import {
  HeaderContext,
  HeaderContextProps
} from '../../contexts/HeaderContext';
import { MdTextRotateUp, MdTextRotationDown } from 'react-icons/md';
import { useOktaAuth } from '../OktaContext';
import { Spinner } from '@org-crowley/enterprise-react-component-library';
import usePagination from '../../hooks/usePagination';
import Pagination from '../Pagination';
import { formatDateOnly } from 'utils/dateUtils';

interface Customer {
  _id: number;
  name: string;
  createdDate: string;
}

// TODO define schemas
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomerListContainer() {
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const [customerData, setCustomerData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const originalData = useRef<Customer[]>([]);
  const { authState } = useOktaAuth();

  const getCustomers = () => {
    authState?.accessToken?.accessToken
      ? axios
        .get(`${process.env.NEXT_PUBLIC_API_BASE}/v1/customers`, {
          headers: {
            Authorization: `Bearer ${authState.accessToken.accessToken}`
          }
        })
        .then((res) => {
          originalData.current = res.data.body;
          setCustomerData(res.data.body);
          setDataIsLoaded(true);
        })
        .catch((err) => console.error('customers err: ', err))
      : setCustomerData([]);
  };

  useEffect(() => {
    getCustomers();
    return () => {
      setCustomerData([]);
      originalData.current = [];
    };
  }, []);

  const getFilteredCustomers = (searchValue: string, customers: any[]) => {
    if (!searchValue) return customers;
    return customers.filter((customer) =>
      customer.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  };

  const filteredCustomers = useMemo(() => {
    return getFilteredCustomers(searchValue, customerData);
 }, [searchValue, customerData]);

  const totalItems = filteredCustomers.length;

  const {
    paginatedData: displayedCustomers,
    handleNext,
    handlePrev,
    handlePageClick,
    currentPage,
    totalPages,
    visiblePageStart,
    maxVisiblePages,
    resetPagination
  } = usePagination(filteredCustomers, 12);

  function handleSearchChange(value: string) {
    setSearchValue(value);
    resetPagination();
  }  

  function sortAsc() {
    const sortedData = [...customerData].sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    setCustomerData(sortedData);
    resetPagination();
  }

  function sortDesc() {
    const sortedData = [...customerData].sort((a, b) => {
      return a.name > b.name ? -1 : 1;
    });
    setCustomerData(sortedData);
    resetPagination();
  }

  const contextValue: HeaderContextProps = {
    useSearch: true,
    setSearchValue: handleSearchChange,
    searchValue,
    searchPlaceholder: 'Search by name',
    useSort: true,
    sortItems: [
      {
        icon: MdTextRotateUp,
        label: 'Customer Name (A-Z)',
        onClick: sortAsc
      },
      {
        icon: MdTextRotationDown,
        label: 'Customer Name (Z-A)',
        onClick: sortDesc
      }
    ],
    useFilter: false,
    filterItems: []
  };

  const customerComponents = displayedCustomers.map((customer) => {
    return (
      <CustomerCard customerId={customer._id} key={customer._id} >
        <CustomerCardHeader customerName={customer.name}></CustomerCardHeader>
        <CustomerCardBody customerId={customer._id} createdDate={`${formatDateOnly(customer.createdDate)}`} ></CustomerCardBody>
      </CustomerCard>
    );
  });

  return (
    <>
      <HeaderContext.Provider value={contextValue}>
        <Header title="Customers" />
      </HeaderContext.Provider>
      {dataIsLoaded ? (
        <div className="grid grid-cols-autofit gap-6 items-stretch">
          {customerComponents}
        </div>
      ) : (
        <div className="flex justify-center h-full items-center">
          <Spinner size="xl" />
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNext={handleNext}
        onPrev={handlePrev}
        onPageClick={handlePageClick}
        visiblePageStart={visiblePageStart}
        maxVisiblePages={maxVisiblePages}
        itemsPerPage={12}
        totalItems={totalItems}
      />
    </>
  );
}
