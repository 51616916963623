import { useState } from "react";

interface PaginationState<T> {
    paginatedData: T[];
    handleNext: () => void;
    handlePrev: () => void;
    handlePageClick: (page: number) => void;
    currentPage: number;
    totalPages: number;
    visiblePageStart: number;
    maxVisiblePages: number;
    resetPagination: () => void;
}

export default function usePagination<T>(data: T[], itemsPerPage: number = 10, maxVisiblePages: number = 5): PaginationState<T> {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const [visiblePageStart, setVisiblePageStart] = useState<number>(1);

    const paginatedData = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const updateVisiblePageStart = (newPage: number) => {
        if (newPage > visiblePageStart + maxVisiblePages - 1) {
            setVisiblePageStart(newPage - maxVisiblePages + 1);
        } else if (newPage < visiblePageStart) {
            setVisiblePageStart(newPage);
        }
    }

    const resetPagination = () => {
        setCurrentPage(1);
        setVisiblePageStart(1);
    };  

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => {
                const nextPage = prev + 1;
                updateVisiblePageStart(nextPage);
                return nextPage;
            });
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => {
                const prevPage = prev - 1;
                updateVisiblePageStart(prevPage);
                return prevPage;
            });
        }
    };

    const handlePageClick = (page: number) => {
        setCurrentPage(page);
        updateVisiblePageStart(page);
    };

    return {
        paginatedData,
        handleNext,
        handlePrev,
        handlePageClick,
        currentPage,
        totalPages,
        visiblePageStart,
        maxVisiblePages,
        resetPagination
    };
}