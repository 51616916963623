export const formatDateTime = (
  utcDate: number | undefined
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'America/New_York'
  };
  let newDateString = 'N/A';
  if (utcDate) {
    const newDate = new Date(utcDate);
    newDateString = new Intl.DateTimeFormat('en-US', options).format(newDate);
  }
  return newDateString;
};

export const formatDateOnly = (
  utcDate: number | undefined
): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'America/New_York'
  };

  let newDateString = 'N/A';

  if (utcDate) {
    const newDate = new Date(utcDate);
    newDateString = new Intl.DateTimeFormat('en-US', options).format(newDate);
  }

  return newDateString;
};
