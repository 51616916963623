export interface CustomerCardProps {
  customerId: number;
  createdDate: string;
}

export function CustomerCardBody({ customerId, createdDate }: CustomerCardProps) {
  return (
    <div className="flex flex-col space-y-3 pl-2">
      {/* <!-- card-header -->  */}
      <section>
        <p className="text-body-medium text-silver-90 font-bold">
          Customer ID:
        </p>
        <p className="text-body-medium font-normal">{customerId}</p>
      </section>
      <section>
        <p className="text-body-medium text-silver-90 font-bold">
          Customer Since:
        </p>
        <p className="text-body-medium font-normal">{createdDate}</p>
      </section>
    </div>
  );
}
