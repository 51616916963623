import { InputTextSearch } from '../../components/InputTextSearch';
import { useContext } from 'react';
import { HeaderContext } from '../../contexts/HeaderContext';
import { HeaderActionDropdown } from './HeaderActionDropdown';

export function HeaderActions() {
  const {
    useSearch,
    searchPlaceholder,
    useSort,
    sortItems,
    useFilter,
    filterItems
  } = useContext(HeaderContext);

  return (
    <div className="flex justify-between sm:flex-row">
      <div className="flex">
        {useSearch ? (
          <InputTextSearch
            placeholder={searchPlaceholder ? searchPlaceholder : 'Search'}
          />
        ) : null}
        {useSort ? (
          <HeaderActionDropdown actionItems={sortItems} variant="Sort" />
        ) : null}
        {useFilter ? (
          <HeaderActionDropdown actionItems={filterItems} variant="Filter" />
        ) : null}
      </div>
    </div>
  );
}
