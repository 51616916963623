import { Typography } from '@org-crowley/enterprise-react-component-library';
import { HeaderActions } from './HeaderActions';
import cn from 'classnames';

export interface HeaderProps {
  title: string;
  subTitle?: string;
  showActions?: boolean;
  className?: string;
}

export function Header({ 
  title, 
  subTitle, 
  showActions = true, 
  className,
 }: HeaderProps) {
  
  return (
    <header
      className={cn(
        'flex',
        'flex-col',     
        'pb-6',
        'lg:flex-row',   
        'lg:justify-between',
        className
      )}
    >
      <div className="block lg:hidden">
        <Typography variant="h600" className="text-blue-80 pb-4" as="h1">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            variant="body-small"
            className="text-blue-80 -mt-3"
            as="p"
          >
            {subTitle}
          </Typography>
        )}
      </div>
      
      <div className="hidden lg:flex lg:items-center"> 
        <Typography variant="h700" className="text-blue-80 mr-3" as="h1">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            variant="body-medium"
            className="text-blue-80"
            as="p"
          >
            {subTitle}
          </Typography>
        )}
      </div>

      {showActions && <HeaderActions />}
    </header>
  );
}
