import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface PaginationProps {
    currentPage?: number;
    totalPages?: number;
    onNext?: () => void;
    onPrev?: () => void;
    onPageClick?: (pageNumber: number) => void;
    visiblePageStart: number;
    maxVisiblePages?: number;
    itemsPerPage?: number;
    totalItems?: number;
}

export default function Pagination({
    currentPage = 1,
    totalPages = 1,
    onNext,
    onPrev,
    onPageClick,
    visiblePageStart = 1,
    maxVisiblePages = 5,
    itemsPerPage = 10,
    totalItems = 0,
}: PaginationProps) {
    
    const end = Math.min(visiblePageStart + maxVisiblePages - 1, totalPages);
    const pagesArray = [...Array(end - visiblePageStart + 1)].map((_, index) => index + visiblePageStart);

    return (
        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between rounded-lg bg-white mt-6 px-4 py-5 sm:px-6">
            <div className="text-center pb-3 sm:pb-0 sm:text-left sm:flex-1">
                <p className="text-body-sm text-gray-50">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} results
                </p>
            </div>
            <div className="sm:flex-1 sm:text-right">
                <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                    <a
                        href="#"
                        role="button"
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-10 hover:bg-blue-5 focus:z-20 focus:outline-offset-0"
                        onClick={(e) => {
                            e.preventDefault();
                            onPrev && onPrev();
                        }}
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </a>

                    {/* Render the page buttons */}
                    {pagesArray.map(pageNum => (
                        <a
                            key={pageNum}
                            role="button"
                            href="#"
                            className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${pageNum === currentPage ? 'bg-blue-80 text-white' : 'text-blue-80'
                                } ring-1 ring-inset ring-gray-10 hover:border-blue-5 focus:z-20 focus:outline-offset-0`}
                            onClick={(e) => {
                                e.preventDefault();
                                onPageClick && onPageClick(pageNum);
                            }}
                        >
                            {pageNum}
                        </a>
                    ))}

                    <a
                        href="#"
                        role="button"
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-10 hover:bg-blue-5 focus:z-20 focus:outline-offset-0"
                        onClick={(e) => {
                            e.preventDefault();
                            onNext && onNext();
                        }}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </a>
                </nav>
            </div>
        </div>
    );
}
